<template>
  <div class="login">
    <div class="login__container">
      <div class="login__container--left">
        <img
          src="../../assets/branding/repair-img.png"
          alt="Branding image"
        >
      </div>
      <div class="login__container--right d-flex flex-column justify-content-center align-items-center">

        <div class="max-w-450px d-flex flex-column py-4">
          <img
            class="aljay-logo align-self-center align-self-md-start"
            :src="companyLogo"
            alt="Aljay logo"
          >

          <div class="header mt-2">
            <h1 class="title">
              Logistics Repair & Maintenance System
            </h1>
            <h2 class="subtitle">
              Sign In
            </h2>
          </div>
          <div class="w-100 mt-3">
            <ValidationObserver
              ref="signinForm"
              v-slot="{ handleSubmit }"
            >
              <form
                novalidate
                @submit.prevent="handleSubmit(onSignIn)"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="email"
                  name="email"
                  rules="required|email"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                    label-class="font-weight-bold"
                    class="mb-2"
                  >
                    <b-input
                      id="email"
                      v-model="credentials.email"
                      type="text"
                      placeholder="enter email here"
                      autocomplete="off"
                      :state="errors.length > 0 ? false : null"
                      :disabled="state.busy"
                    />
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider
                  #default="{ errors }"
                  vid="password"
                  name="password"
                  rules="required"
                >
                  <b-form-group
                    label="Password"
                    label-for="password"
                    label-class="font-weight-bold"
                    class="mb-3"
                  >
                    <b-input-group
                      :class="errors.length > 0 ? 'is-invalid' : ''"
                    >
                      <b-input
                        id="password"
                        v-model="credentials.password"
                        placeholder="enter password here"
                        autocomplete="off"
                        :type="passwordType"
                        :state="errors.length > 0 ? false : null"
                        :disabled="state.busy"
                      />
                      <b-input-group-append>
                        <b-button
                          class="pl-2 pr-1 text-dark"
                          variant="warning"
                          style="border-radius: 0px 5px 5px 0px; background-color: var(--iq-body-color); border: 1px solid #ccc; border-left: 0px solid transparent;"
                          :disabled="state.busy"
                          @click="togglePassword"
                        >
                          <i
                            v-if="state.visible_password"
                            class="ri-eye-line h5"
                          />
                          <i
                            v-else
                            class="ri-eye-off-line h5"
                          />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                    <div
                      v-if="errors.length > 0"
                      class="invalid-feedback"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
                <b-row class="px-2 pt-2">

                  <b-overlay
                    class="col-12 px-1"
                    rounded
                    opacity="0.1"
                    spinner-variant="warning"
                    spinner-small
                    :show="state.local"
                  >
                    <b-button
                      size="sm"
                      type="submit"
                      class="w-100"
                      variant="success"
                      :disabled="state.busy"
                    >
                      Sign In
                    </b-button>
                  </b-overlay>

                  <b-col class="text-divider">
                    <span>&nbsp; or sign in with</span>
                  </b-col>

                  <b-overlay
                    class="col-12 px-1"
                    rounded
                    opacity="0.1"
                    spinner-variant="success"
                    spinner-small
                    :show="state.sso"
                  >
                    <b-button
                      size="sm"
                      type="button"
                      class="w-100"
                      variant="warning"
                      :disabled="state.busy"
                      @click="onSignInWithAljaySSO"
                    >
                      Aljay SSO
                    </b-button>
                  </b-overlay>

                </b-row>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
      <div class="circle circle__lg" />
      <div class="circle circle__sm" />
    </div>
    <div class="circle circle__one" />
    <div class="circle circle__two" />
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SSharedAuth, SSharedSSO } from '@/services'
import { Oauth2 } from 'universal-social-auth'
import LOGOS from '@/mixins/logos'
import URLS from '@/mixins/url'

export default {
  name: 'VSharedSignIn',

  middleware: ['guest'],

  metaInfo () {
    return {
      title: 'Sign In'
    }
  },

  mixins: [LOGOS, URLS],

  data: () => ({
    state: {
      busy: false,
      local: false,
      sso: false,
      visible_password: false
    },
    credentials: {
      email: null,
      password: null
    },
    Oauth2
  }),

  computed: {

    companyLogo () {
      return this.getImageUrl(this.getCompany())
    },

    passwordType () {
      return this.state.visible_password ? 'text' : 'password'
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    togglePassword () {
      this.state.visible_password = !this.state.visible_password
    },

    async saveAndGetCurrentUser (token) {
      this.$store.dispatch('auth/saveToken', token)
      return new Promise((resolve, reject) => {
        SSharedAuth.getCurrentUser().then(
          ({ data }) => {
            this.$store.dispatch('auth/fetchUserSuccess', data)
            const user = this.$store.getters['auth/user']
            this.$router.replace({
              name: user ? `${user.role}.dashboard` : 'dashboard'
            }, () => {
              resolve(data)
            })
          }
        ).catch(
          error => {
            this.$store.dispatch('auth/fetchUserFailure')
            reject(error)
          }
        )
      })
    },

    async onSignIn () {
      await this.$refs.signinForm.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.state.busy = this.state.local = true
            SSharedAuth.signIn(this.credentials).then(
              ({ data: { token } }) => {
                return this.saveAndGetCurrentUser(token)
              }
            ).finally(() => {
              this.state.busy = this.state.local = false
            })
          }
        }
      )
    },

    async onSignInWithAljaySSO (event) {
      event.preventDefault()
      this.state.busy = this.state.sso = true
      this.$Oauth.authenticate('oauth2', this.Oauth2).then(
        authorized => {
          SSharedSSO.post(authorized).then(
            ({ data: { token } }) => {
              return this.saveAndGetCurrentUser(token)
            }
          ).finally(() => {
            this.state.busy = this.state.sso = false
          })
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>

.text-divider {
  margin: 2em 0;
  line-height: 0;
  text-align: center;
  color: gray;
  padding: 0px 5px;
}

.text-divider span {
  background-color: #ffffff;
  padding: 0 0.5em;
}

.text-divider:before {
  content: " ";
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid gray;
  border-bottom: 1px solid #ffffff;
}

.text-divider span:before  {
  content: "";
  display: block;
  margin-top: -3px;
}

.text-divider span:after  {
  display: block;
  margin-top: -3px;
}

.login {
  background: #f8fafc;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  &__container {
    position: relative;
    background: white;
    display: grid;
    max-width: 1024px;
    grid-template-columns: repeat(5, 1fr);
    margin: 0 auto;
    box-shadow: -1px 3px 63px -3px rgba(227, 219, 219, 0.74);
    -webkit-box-shadow: -1px 3px 63px -3px rgba(227, 219, 219, 0.74);
    -moz-box-shadow: -1px 3px 63px -3px rgba(227, 219, 219, 0.74);
    border-radius: 10px;
    &--left {
      background: #fafafa;
      grid-column: 1 / 3;
      padding: 1rem;
      display: flex;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      img {
        max-width: 100%;
        margin: auto 0;
      }
    }
    &--right {
      padding: 2rem 3rem;
      grid-column: 3 / 9;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 1rem;
      background: transparent;
      .aljay-logo {
        height: 60px;
        max-width: 100%;
        align-self: flex-start;
        margin-left: -0.6rem;
      }
      .title,
      .subtitle {
        line-height: 1.2;
      }
      .title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 0.3rem;
      }
      .subtitle {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .circle {
    position: absolute;
    box-shadow: 1px 0px 32px -10px rgba(26, 5, 5, 0.79);
    -webkit-box-shadow: 1px 0px 32px -10px rgba(26, 5, 5, 0.79);
    -moz-box-shadow: 1px 0px 32px -10px rgba(26, 5, 5, 0.79);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    &__lg {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      top: 0;
      right: 0;
      background: #06c270;
      transform: translate(20px, -20px);
    }
    &__sm {
      top: 50%;
      right: 0;
      background: #ffe74d;
      transform: translate(15px, -25px);
    }

    &__one {
      left: 50%;
      top: 2rem;
      background: #ffe74d;
    }
    &__two {
      left: 15%;
      bottom: 2rem;
      background: #06c270;
    }
  }
}

@media screen and (max-width: 1200px) {
  .login {
    &__container {
      max-width: 992px;
      &--left {
        .wifi-icon {
          max-width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .login {
    &__container {
      max-width: 830px;
      &--left {
        .wifi-icon {
          max-width: 100%;
        }
      }
    }
    .circle {
      &__lg {
        width: 40px;
        height: 40px;
      }

      &__one {

        top: 1rem;

      }
      &__two {

        bottom: 1rem;

      }
    }
  }
}

@media screen and (max-width: 867px) {
  .login {
    &__container {
      max-width: 768px;
      &--left {
        .wifi-icon {
          max-width: 100%;
        }
      }
      &--right {
        .title {
          font-size: 18px;
        }
      }
    }
    .circle {
      &__lg {
        width: 40px;
        height: 40px;
      }

      &__one {

        top: 1rem;

      }
      &__two {
        left: 15%;
        bottom: 2rem;

      }
    }
  }
}

@media screen and (max-width: 768px) {
  .login {
    &__container {
      width: 100%;
      margin: 0 2rem;
      grid-template-columns: 1fr;
      &--left {
        display: none;
      }
      &--right {
        padding: 2rem 3rem;
        grid-column: 1/5;
        .aljay-logo {
        margin: 0 auto;
        }

        .title {
          text-align: center;
          font-size: 24px;
          margin-bottom: 1rem;
        }
        .subtitle {
          font-size: 20px;

        }
      }
    }
    .circle {
      &__one {
       top: 0;
      }
      &__two {
        bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 567px) {
  .login {
    &__container {
      width: 100%;
      margin: 0 2rem;
      grid-template-columns: 1fr;

      &--left {
        display: none;
      }
      &--right {
         gap: 1rem;
        padding: 1rem;

        .title {
          text-align: center;
          font-size: 1.5rem;
          margin-bottom: 1.5rem;
        }
        .subtitle {
          font-size: 1rem;

        }
      }
    }
    .circle {
      &__sm {
        display: none;
      }
      &__one {
       top: 1rem;
      }
      &__two {
        bottom: 1rem;
      }
    }
  }
}

@media screen and (max-width: 367px) {
  .login {
    &__container {

      &--right {
        .title {
          margin-bottom: 0.5rem;
        }
      }
    }
    .circle {
      &__one {
       top: 2rem;
      }
      &__two {
        bottom: 2rem;
      }
    }
  }
}
</style>
